


































































import Vue from "vue";
import { Action } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import {
  required,
  minLength,
  sameAs,
  maxLength,
} from "vuelidate/lib/validators";

import api from "@/core/utils/api";
import { passwordSettings } from "@/settings";

@Component({
  mixins: [validationMixin],
  validations: {
    passwordInput: {
      required,
      minLength: minLength(passwordSettings.minLength),
      maxLength: maxLength(passwordSettings.maxLength),
      passwordRules: passwordSettings.passwordRules,
    },
    confirmPasswordInput: {
      required,
      sameAsPassword: sameAs("passwordInput"),
    },
  },
})
export default class ResetPassword extends Vue {
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;

  token = "";
  done = false;
  email = "";
  loading = false;
  showPass = false;
  error: any = null;
  passwordInput = "";
  confirmPasswordInput = "";

  get passwordErrors() {
    const errors: any[] = [];
    if (!this.$v.passwordInput!.$dirty) return errors;
    if (!this.$v.passwordInput!.required)
      errors.push(this.$t("registerSection.passRequired"));
    if (!this.$v.passwordInput!.minLength)
      errors.push(
        this.$t("registerSection.passMin", { min: passwordSettings.minLength }),
      );
    !this.$v.passwordInput!.maxLength &&
      errors.push(
        this.$t("registerSection.passMax", { max: passwordSettings.maxLength }),
      );
    if (!this.$v.passwordInput!.passwordRules)
      errors.push(this.$t("registerSection.passRules"));
    return errors;
  }

  get confirmPasswordErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.confirmPasswordInput!.$dirty) return errors;
    !this.$v.confirmPasswordInput!.required &&
      errors.push(this.$t("passwordResetSection.confirmPassRequired"));
    !this.$v.confirmPasswordInput!.sameAsPassword &&
      errors.push(this.$t("passwordResetSection.passNoMatch"));
    return errors;
  }

  async submit() {
    this.$v.$touch();
    if (this.$v.$invalid || this.passwordErrors.length > 0) {
      const msg = this.$t("forms.invaliform").toString();
      this.displaySnackbar(msg);
      return;
    }

    this.loading = true;
    try {
      const end = "/api/Account/ResetPassword";
      const data = {
        token: this.token,
        email: this.email,
        newPassword: this.passwordInput,
      };
      await api.post(end, data);
      this.done = true;
      const msg = this.$t("snack.auth.reset").toString();
      this.displaySnackbar(msg);
      setTimeout(() => this.$router.push("/auth/login"), 5 * 1000);
    } catch (error) {
      this.error = error;
      const msg = this.$t("snack.auth.resetfail").toString();
      this.displaySnackbar(msg);
    }
    this.loading = false;
  }

  get hasError() {
    return (
      !!this.error ||
      this.passwordErrors.length > 0 ||
      this.confirmPasswordErrors.length > 0
    );
  }

  created() {
    this.email = this.$route.query.email as string;
    this.token = this.$route.query.token as string;
    console.log(this.email, this.token);
    if (!this.email || !this.token) this.$router.push("/auth/login");
  }

  beforeDestroy() {
    this.passwordInput = "";
    this.confirmPasswordInput = "";
  }
}
